<template>
  <section v-if="images.length"
           class="portfolio">
    <div class="container portfolio__container">
      <h2 class="title portfolio__title">
        {{ $t('thePortfolio.title') }}
      </h2>
      <div class="portfolio__swiper-container">
        <div class="portfolio__swiper">
          <SwiperGallery :files="images"
                         :swiperOptions="swiperOptions" />
        </div>
      </div>
      <NuxtLink v-if="!hideMoreButton"
                to="/portfolio"
                class="btn btn__big btn__portfolio">
        {{ $t('thePortfolio.portfolioButton') }}
      </NuxtLink>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { SwiperOptions } from 'swiper';
import { SwiperGallery } from '../../ui';
import type { IThePortfolioProps } from './types';

const portfoliosStore = usePortfoliosStore();

const props = defineProps({
  hideMoreButton: {
    type: Boolean as PropType<IThePortfolioProps['hideMoreButton']>,
    default: false,
  }
});

const images = computed(
  () => portfoliosStore.allImages
);

const swiperOptions: SwiperOptions = {
  navigation: true,
  pagination: true,
  spaceBetween: 10,
};
</script>

<style lang="scss" scoped>
@import "./styles.scss";
</style>
